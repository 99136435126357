import { useSearchParams } from 'react-router';
import { Select, type SelectProps, Text, Tooltip } from '@venncity/venn-ds';
import { isEmpty } from 'lodash-es';
import { useSearchNavigate } from '~/utils/search-params';

export const SelectFilter = (props: SelectProps) => {
  const [searchParams] = useSearchParams();
  const searchNavigate = useSearchNavigate();

  const handleSelectionChange = (selection: string) => {
    searchNavigate({
      [props.id as string]: isEmpty(selection) ? undefined : selection,
      page: undefined,
    });
  };

  return (
    <div className="flex flex-col gap-1">
      <Text>{props.placeholder}</Text>
      <Select
        showSearch
        key={props.id}
        popupMatchSelectWidth={false}
        onChange={handleSelectionChange}
        optionFilterProp="label"
        style={{ width: 180 }}
        defaultValue={searchParams.getAll(props.id as string)}
        maxTagCount="responsive"
        maxTagPlaceholder={(omittedValues) => (
          <Tooltip
            styles={{ root: { pointerEvents: 'none' } }}
            title={omittedValues.map(({ label }) => label).join(', ')}>
            <span>+{omittedValues.length}</span>
          </Tooltip>
        )}
        {...props}
      />
    </div>
  );
};
