import { Table as AntTable, TableColumnType } from 'antd';

export const Table = AntTable;
export type ColumnsType<RecordType> = TableColumnType<RecordType>[];
export type { TableProps, TablePaginationConfig } from 'antd';
export type {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
  SortOrder,
} from 'antd/es/table/interface';
