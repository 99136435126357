import { DatePicker, DatePickerProps, Text } from '@venncity/venn-ds';
import { useSearchNavigate } from '~/utils/search-params';
import { isEmpty } from 'lodash-es';
import dayjs, { Dayjs } from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
import timezonePlugin from 'dayjs/plugin/timezone';
import { useSearchParams } from 'react-router';
import { useEffect, useState } from 'react';

const { RangePicker } = DatePicker;
dayjs.extend(utcPlugin);
dayjs.extend(timezonePlugin);

interface DateFilterProps extends DatePickerProps {
  timezone: string;
}

export const DateFilter = (props: DateFilterProps) => {
  const [searchParams] = useSearchParams();
  const searchNavigate = useSearchNavigate();

  const timezone = props.timezone;

  const startDate = searchParams.get(`start-${props.id}`);
  const endDate = searchParams.get(`end-${props.id}`);

  const [dates, setDates] = useState<[Dayjs, Dayjs] | undefined>(
    startDate && endDate ? [dayjs(startDate).tz(timezone), dayjs(endDate).tz(timezone)] : undefined,
  );

  useEffect(() => {
    if (startDate && endDate) {
      setDates([dayjs(startDate).tz(timezone), dayjs(endDate).tz(timezone)]);
    } else {
      setDates(undefined);
    }
  }, [startDate, endDate, timezone]);

  const handleSelectionChange = (date: Dayjs) => {
    searchNavigate({
      [`start-${props.id}`]: isEmpty(date)
        ? undefined
        : date.startOf('M').tz(timezone, true).toISOString(),
      [`end-${props.id}`]: isEmpty(date)
        ? undefined
        : date.endOf('M').tz(timezone, true).toISOString(),
      page: undefined,
    });
  };

  const handleRangeSelectionChange = (dates: [Dayjs, Dayjs]) => {
    setDates(dates ? [dayjs(dates[0]).tz(timezone), dayjs(dates[1]).tz(timezone)] : undefined);
    searchNavigate({
      [`start-${props.id}`]: isEmpty(dates)
        ? undefined
        : dates[0].startOf('D').tz(timezone, true).toISOString(),
      [`end-${props.id}`]: isEmpty(dates)
        ? undefined
        : dates[1].endOf('D').tz(timezone, true).toISOString(),
      page: undefined,
    });
  };

  return (
    <div className="flex flex-col gap-1">
      <Text>{props.placeholder}</Text>
      {props.picker ? (
        <DatePicker
          allowClear={props.allowClear}
          picker={props.picker}
          onChange={handleSelectionChange}
          value={dayjs(startDate).tz(timezone)}
        />
      ) : (
        <RangePicker
          allowClear={props.allowClear}
          value={dates}
          // @ts-expect-error - antd  error
          onChange={handleRangeSelectionChange}
        />
      )}
    </div>
  );
};
