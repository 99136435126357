import { Input, SearchOutlined } from '@venncity/venn-ds';
import { debounce } from 'lodash-es';
import type { ChangeEvent } from 'react';
import { useCallback, useEffect, useState } from 'react';

interface SearchInputProps {
  onSearchChange: (value: string) => void;
  placeholder?: string;
  debounceMs?: number;
}

export const SearchInput = (props: SearchInputProps) => {
  const { onSearchChange, placeholder, debounceMs = 500 } = props;
  const [value, setValue] = useState('');

  const debouncedCallback = useCallback(
    debounce((value) => {
      onSearchChange?.(value);
    }, debounceMs),
    [onSearchChange, debounceMs],
  );

  useEffect(() => {
    setValue(value);
  }, [value]);

  useEffect(() => {
    // Cleanup the debounced function on unmount
    return () => {
      debouncedCallback.cancel();
    };
  }, [debouncedCallback]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setValue(newValue);
    debouncedCallback(newValue);
  };

  return (
    <Input
      addonAfter={<SearchOutlined />}
      onChange={handleChange}
      placeholder={placeholder || 'Search'}
      allowClear={true}
    />
  );
};
