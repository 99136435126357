import { Table, Text } from '@venncity/venn-ds';
import type { TableProps, SorterResult, TableCurrentDataSource } from '@venncity/venn-ds';
import React from 'react';
import { SearchInput } from './SearchInput';
import { SelectFilter } from './Filters/SelectFilter';
import { DateFilter } from './Filters/DateFilter';
import { useNavigate, useSearchParams } from 'react-router';
import { useUrl } from '~/utils/search-params';

export interface BaseFilterProps {
  id: string;
  type: 'select' | 'datePicker';
  placeholder?: string;
  allowClear?: boolean;
}

export interface SelectFilterProps extends BaseFilterProps {
  type: 'select';
  options?: { value: string; label: string }[];
  mode?: 'multiple';
}

export interface DatePickerFilterProps extends BaseFilterProps {
  type: 'datePicker';
  picker?: 'date' | 'week' | 'month' | 'quarter' | 'year';
  timezone: string;
}

export type FilterProps = SelectFilterProps | DatePickerFilterProps;

interface GeneralTableProps<T> extends TableProps<T> {
  filters?: FilterProps[];
  emptyData: React.ReactNode;
  onSearchChange?: (e: string) => void;
  searchPlaceholder?: string;
  radioSelection?: React.ReactNode;
}

export function GeneralTable<T>(props: GeneralTableProps<T>) {
  const { filters, onSearchChange, emptyData, radioSelection, searchPlaceholder, ...rest } = props;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setSort } = useUrl();

  const handleTableChange = (
    _: unknown,
    __: unknown,
    sorter: SorterResult<T> | SorterResult<T>[],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    extra: TableCurrentDataSource<T>,
  ) => {
    // for now we only support one sorter, will need more work here if we want multiple sorters
    if (Array.isArray(sorter)) {
      sorter = sorter[0];
    }

    if (sorter.columnKey === undefined) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete('order-by');
      navigate({ pathname: '', search: newSearchParams.toString() }, { replace: true });
    } else {
      setSort({
        column: sorter.columnKey!.toString(),
        direction: sorter.order === 'ascend' ? 'ascending' : 'descending',
      });
    }
  };

  const getFilter = (filter: FilterProps) => {
    switch (filter.type) {
      case 'select':
        return (
          <SelectFilter
            key={filter.id}
            id={filter.id}
            options={filter.options}
            placeholder={filter.placeholder}
            allowClear={filter.allowClear}
            mode={filter.mode}
          />
        );
      case 'datePicker':
        return (
          <DateFilter
            id={filter.id}
            key={filter.id}
            allowClear={filter.allowClear}
            placeholder={filter.placeholder}
            picker={filter.picker}
            timezone={filter.timezone}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="h-full">
      <div className="mb-4 flex justify-between">
        <div className="flex gap-2">{filters && filters.map((filter) => getFilter(filter))}</div>
        {onSearchChange && (
          <div className="flex flex-col justify-end">
            <SearchInput placeholder={searchPlaceholder} onSearchChange={onSearchChange} />
          </div>
        )}
        {radioSelection && <div className="flex flex-col justify-end">{radioSelection}</div>}
      </div>
      <div className="mb-6">
        <Text type="secondary">You have {rest.dataSource?.length || 0} items in this table</Text>
      </div>
      {rest.dataSource?.length ? (
        <Table<T> virtual onChange={handleTableChange} {...rest} />
      ) : (
        emptyData
      )}
    </div>
  );
}
